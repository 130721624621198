import { render, staticRenderFns } from "./bridge-monitor.vue?vue&type=template&id=45675514&scoped=true"
import script from "./bridge-monitor.vue?vue&type=script&lang=js"
export * from "./bridge-monitor.vue?vue&type=script&lang=js"
import style0 from "./bridge-monitor.vue?vue&type=style&index=0&id=45675514&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_4izezhgveghju4kikdy2dserta/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45675514",
  null
  
)

export default component.exports